.home,
.page-template-rdv-centers,
.page-template-rdv-centers-search-result{
  .wrapper-container{
    .map-container{
      .right-column{
        .filter{
          display: none;
        }
      }
    }
  }
}

.page-template-partner-centers {
  .wrapper-container{
    .map-container{
      .right-column{
        .filter{
          display: block !important;
        }
      }
    }
  }
}

.filter{
  select{
    cursor: pointer;
  }
}

.empty-centre{
  height: 100%;
  text-align: center;

  p{
    padding: 15px;
  }
}

.mapboxgl-popup{
  max-width: 308px!important;
}

.home{
  .rdv-dispo-alaxione{
    .rdv-search{
      .select-box:disabled {
        background-color: rgba(118, 118, 118, 0.3);
      }
    }
  }
  .mapboxgl-ctrl-geocoder{
    .mapboxgl-ctrl-geocoder--icon-search{
      fill: #467ca0;
    }
  }
}

.page-template-partner-centers {
  .title-home{
    display: flex;
    flex-wrap: wrap;

    .partner{
      max-width: 173px;
      padding: 15px;
      background-color: #ffffff;
      border-radius: 10px;
      max-height: 83px;
      margin-right: 20px;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .wrapper-container {
    .map-container {
      .right-column {
        .items {
          background-color: transparent;
        }
      }
    }
  }
}

.mapboxgl-ctrl-geocoder {

  .suggestions{
    padding: 3px 15px;
    border: 1px solid grey;

    li{
      a{
        cursor: pointer;
        color: #707070;
        font-weight: normal;
        font-size: 16px;
      }
    }
  }

  .mapboxgl-ctrl-geocoder--input{
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 15px 50px 15px 15px;
    color: #707070;
    box-shadow: 0 3px 15px rgb(70 124 160 / 20%);
    border-radius: 10px;
  }

  .mapboxgl-ctrl-geocoder--icon{
    position: absolute;

    &.mapboxgl-ctrl-geocoder--icon-close, &.mapboxgl-ctrl-geocoder--icon-loading{
      display: none;
    }
  }

  .mapboxgl-ctrl-geocoder--icon-search{
    fill: #707070;
    width: 35px;
    height: 35px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.mapboxgl-ctrl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .suggestions-wrapper {
    background-color: #ffffff;
  }
}

.map-content {
  padding-top: 50px;
  margin-bottom: 50px;
  position: relative;
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: calc(100% + 59px);
    width: 100vw;
    z-index: -1;
    background-color: #e3f1fb;
    transform: translate(-50%, 0);
  }
  &.partner-center {
    &::before {
      display: none;
    }
  }
}

.wrapper-container {
  max-width: 1250px;
  margin: 0 auto;

  .main-title {
    font-size: 36px;
    font-weight: bold;
    color: #153153;
    padding: 0 49px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 30px;
      height: 4px;
      background: #6bb2e3 0% 0% no-repeat padding-box;
      opacity: 1;
      position: absolute;
      left: 0;
    }
  }

  .map-container {
    display: flex;
    margin-bottom: 90px;
    padding-top: 100px;
    position: relative;
    ::-webkit-scrollbar {
      width: 10px;
      background-color: #ebeded;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #eef3f6;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #17385e;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      font-family: 'OpenSans-bold';
      color: #17385e;
    }

    .multiple select {
      margin-bottom: 12px;
    }

    .map-display {
      width: 100%;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      font-size: 14px;
      background-color: #ffffff;
      border: solid 2px #6ab2e3;
      padding: 23px;
      text-align: center;
      color: #17385e;
      cursor: pointer;
      margin: 30px 0 5px 0;
      letter-spacing: 2px;
    }

    .filter{
      margin-bottom: 20px;
      width: 100%;

      select{
        padding: 15px 0 15px 20px;
        color: #153153;
        font-size: 16px;
        border-radius: 10px;
        width: calc(100% - 20px);
      }
    }

    .right-column {
      width: 40%;
      overflow: hidden;

      .loader {
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #153153;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
        margin: 25% auto;
      }

      /* Safari */
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .items {
        overflow-y: scroll;
        box-shadow: 0 2px 40px 0 rgba(7, 26, 30, 0.07);
        display: flex;
        flex-direction: column;
        background-color: #e3f1fb;

        .item {
          cursor: pointer;
          padding: 25px;
          position: relative;
          box-shadow: 0 2px 4px 0 rgba(7, 26, 30, 0.12);
          background-color: #ffffff;
          margin-right: 10px;
          border-radius: 10px;

          &:not(:last-child) {
            margin-bottom: 25px;
          }

          .wrapper-top {
            display: flex;
            align-items: flex-start;
            padding-bottom: 23.5px;
            margin-bottom: 22.5px;
            border-bottom: 1px solid rgb(112 112 112 / 24%);

            .box-left {
              width: 65%;
              line-height: 24px;
            }
          }

          .content-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .content-calendar {
              margin-left: 15px;
              color: #153153;
              line-height: 22px;
              font-size: 16px;
              p:last-child {
                font-weight: 600;
                font-size: 15.5px;
              }
            }

            .underlined-link {
              margin-top: 0;
              background: #467ca0;
              padding: 14px 25px;
              border-radius: 40px;
              color: #fff;
              line-height: 22px;
              &:hover{
                background-color: #153153;
              }
            }
          }

          .tel {
            font-family: "Open Sans", sans-serif;
            color: #333333;
          }
          .mail {
            margin-bottom: 12px;
          }
          .mail,
          .calendar,
          .link {
            display: flex;
            align-items: center;
            img {
              min-width: 32px;
            }
            a {
              font-family: "Open Sans", sans-serif;
              font-weight: bold;
              align-self: center;
              margin-left: 13px;
              color: #17385e;
              font-size: 18px;
            }
          }

          .offer {
            margin: 12px 0;
            width: 100%;
            flex-wrap: wrap;
            div {
              font-size: 16px;
            }
          }

          .typology {
            display: flex;
            width: 35%;
            flex-wrap: wrap;
            justify-content: flex-end;

            a {
              background-color: #e3f1fb;
              padding: 0px 10px;
              color: #153153;
              border-radius: 17.5px;
              text-align: center;
              font-size: 12px;
              margin-left: 5px;
              font-family: "OpenSans-bold";
            }
          }
          .surgeon {
            margin: 25px 0;
            display: flex;
            .picture {
              img {
                max-width: 130px;
                max-height: 130px;
              }
            }
            .info {
              width: 70%;
              margin-left: 20px;

              .name {
                font-size: 20px;
                font-family: "Open Sans", sans-serif;
                font-weight: bold;
              }
            }
          }
        }
      }
      .empty p {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        align-self: center;
        color: #17385e;
        font-size: 18px;
      }
    }
    .mapboxgl-map {
      margin-right: 25px;
      box-shadow: 0 10px 50px 0 rgba(7, 26, 30, 0.12);
      width: 60%;
      height: 550px;

      &.active {
        display: block;
        margin-top: 25px;
      }
      .popup-text {
        .title{
          &:after{
            display: block;
            content: "";
            width: 100%;
            height: 1px;
            background-color: #6BB2E3;
            opacity: .3;
            margin-top: 10px;
          }
        }
        .appointment{
          margin-top: 30px;
          margin-bottom: 14px;

          .underlined-link {
            background: #467ca0;
            padding: 14px 25px;
            border-radius: 40px;
            color: #fff;
            line-height: 22px;
            word-break: keep-all;
            display: block;
            font-size: 16px;

            &.disable{
              background-color: #b5cad9;
              color: #dfeaf2;
              cursor: default;
            }
          }
        }

        p {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        .link,
        .calendar,
        .mail {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          a {
            font-size: 14px;
            margin-left: 4px;
            line-height: 1.2;

            img {
              max-width: 26px;
            }
          }
        }
      }
      .mapboxgl-marker {
        svg {
          g {
            fill: #17385e;
          }
        }
      }
      .mapboxgl-marker-selected {
        svg {
          g {
            fill: #47a7ed;
          }
        }
      }
      .marker-disable {
        svg {
          g {
            fill: #9f9f9f;
          }
        }
      }
    }

    &.style-2 {
      .title, .box-left p , .tel , .content-calendar p, .popup-text p {
        color: #153153 !important;
      }

      .right-column {
        .items {
          &::-webkit-scrollbar-thumb {
            background:#467CA0;
          }
        }
      }
    }
  }

  .offer {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 20px;
    .title {
      font-size: 22px;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
    }
    .date {
      p {
        color: #cbced1;
      }
    }
    .center {
      margin: 10px 0;
      .address {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
      }
    }
    .contract-type {
      p {
        color: #83bce6;
      }
    }
    .description,
    .reference {
      p {
        color: #767676;
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
    .button {
      background-color: #41ccd1;
      border: 2px solid #41ccd1;
      padding: 20px 65px 20px 65px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 30%;
      margin: 0 auto;
      button {
        background: none;
      }
      a {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 2px;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-size: 14px;
      }
      &:hover {
        background-color: #ffffff;
        box-shadow: 1px 1px 12px 0 #ccc;
        opacity: 1;
        transition: all 0.2s ease;
        border: 2px solid #ffffff;
        a {
          color: #41ccd1;
        }
      }
    }
    &.single {
      .title {
        h1 {
          color: #17385e;
          line-height: 1.1;
        }
      }
      .description {
        p {
          color: #000000;
          font-size: 16px;
          line-height: 1.88;
        }
        ul,
        ol {
          font-family: "Open Sans", sans-serif;
          li {
            font-size: 16px;
            line-height: 24px;
            position: relative;
            padding: 5px 5px 5px 30px;

            &::before {
              content: "";
              height: 6px;
              width: 6px;
              background: #000000;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 15px;
            }
          }
        }

        ol {
          li {
            padding: 5px 5px 5px 0;
            list-style-type: decimal;
            list-style-position: inside;

            &::before {
              content: none;
            }

            ol {
              li {
                margin: 5px 0 0 40px;
              }
            }
          }
        }
      }
      .button {
        background-color: #6ab2e3;
        border: 2px solid #6ab2e3;
        padding: 23px 65px;
        width: 482px;
        a {
          text-transform: uppercase;
          width: 100%;
        }
        &:hover {
          background-color: #ffffff;
          border: 2px solid #ffffff;
          box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
          opacity: 1;
          transition: all 0.2s ease;
          a {
            color: #6ab2e3;
          }
        }
      }
    }
  }
  .single div {
    margin-bottom: 15px;
  }
  .item-selected {
    border: 1px solid #6ab2e3;
    box-shadow: 3px 3px 3px #6ab2e3;
    border-radius: 5px;
  }
  .mapboxgl-popup-content{
    padding: 20px 45px 20px 20px;

    .mapboxgl-popup-close-button {
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 28px;
      line-height: 0.6;
      color: #153153;
    }

    .close-btn{
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 320px) {
  .home,
  .page-template-rdv-centers,
  .page-template-rdv-centers-search-result,
  .page-template-partner-centers{
    .wrapper-container {
      .map-container {
        .filter {
          &.mobile {
            display: none;
          }
        }
      }
    }
  }

  .map-content {
    padding-top: 30px;
  }
  div#mapbox {
    padding: 0 11px;
  }
  .wrapper-container {
    .main-title {
      font-size: 20px;
      margin-bottom: 20px;
      &::before {
        left: 11px;
      }
    }
    .map-container {
      flex-wrap: wrap;
      margin-bottom: 0;
      padding-top: 0;
      &::before {
        content: none;
      }
      .map-display {
        display: block;
      }
      .filter{
        &.mobile{
          display: block;
        }
      }

      .right-column {
        width: 100%;
        margin-left: 0;
        height: auto;
        padding-bottom: 20px;

        .filter{
          display: none;
        }

        .show-map{
          display: flex;
          padding: 14px 61px;
          border-radius: 25px;
          border: solid 2px #6BB2E3;
          background-color: #ffffff;
          justify-content: center;
          align-items: center;
          margin: 0 35px 20px 35px;

          p{
            font-size: 16px;
            color: #153153;
            text-align: center;
            font-weight: 700;
          }
        }

        .items {
          height: 840px;
          .item {
            padding: 25px 30px;
            .wrapper-top,
            .content-bottom {
              flex-wrap: wrap;
              .box-left,
              .typology {
                width: 100%;
                justify-content: flex-start;
                margin-top: 18px;
              }
              .box-left {
                margin-top: 0;
              }
              &.partner-center-link {
                justify-content: center;
              }
            }

            .content-bottom {
              .underlined-link {
                margin-top: 15.5px;
                padding: 14px 18px;
              }
            }

            .mail {
              margin-bottom: 12px;
            }
            .typology {
              a {
                margin-bottom: 5px;
                margin-right: 5px;
                margin-left: 0;
              }
            }
          }
        }
      }
      .mapboxgl-map {
        width: 100%;
        display: none;
        margin-right: 0;
        height: 300px;


        &.show-part{
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    .offer {
      &.single {
        .title {
          h1 {
            line-height: 1.1;
          }
        }
        .description {
          p {
            line-height: 1.88;
          }
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .map-content {
    padding-top: 50px;
  }
  div#mapbox {
    padding: 0;
  }
  .wrapper-container {
    .main-title {
      font-size: 24px;
      margin-bottom: 50px;
      &::before {
        left: 0px;
      }
    }
    .map-container {
      flex-wrap: nowrap;
      margin-bottom: 90px;
      margin-top: 25px;

      .map-display {
        display: none;
      }

      .filter{
        &.mobile{
          display: none;
        }
      }

      .right-column {
        width: 45%;
        padding-bottom: 0;

        .filter{
          display: block;
        }

        .show-map{
          display: none;
        }

        .items {
          .item {
            .mail {
              margin-bottom: 12px;
            }
          }
        }
      }
      .mapboxgl-map {
        width: 55%;
        display: block;
        margin-right: 25px;
        height: 550px;
      }
    }

    .offer {
      &.single {
        .title {
          h1 {
            line-height: 1.1;
          }
        }
        .description {
          p {
            line-height: 1.88;
          }
        }
        .button {
          width: 482px;
        }
      }
    }
  }

  .page-template-partner-centers {
    .wrapper-container {
      .map-container {
        .right-column {
          width: 30%;
        }
        .mapboxgl-map {
          width: 60%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {

  .home{
    .wrapper-container {
      .map-container {
        margin-bottom: 0;
      }
    }
  }

  .page-template-rdv-centers {
    .wrapper-container {
      .map-container {
        .right-column {
          .items {
            height: 100%;
          }
        }
      }
    }
  }

  .wrapper-container {
    .map-container {
      flex-wrap: nowrap;
      margin-bottom: 90px;
      padding-bottom: 50px;
      &::before {
        content: "";
      }
      .map-display {
        display: none;
      }
      .right-column {
        width: 45%;
        height: 550px;

        .items {
          height: calc( 100% - 75px );
          .item {
            padding: 25px;
            .wrapper-top {
              flex-wrap: nowrap;
              .box-left {
                width: 65%;
              }
              .typology {
                width: 35%;
                justify-content: flex-end;
                margin-top: 0;
                a {
                  line-height: 1.5;
                  margin-right: 5px;
                  margin-left: 0;
                }
              }
            }
            .content-bottom {
              .underlined-link {
                margin-top: 15px;
                padding: 14px 20px;
                font-size: 15px;
                &.disable{
                  background-color: #b5cad9;
                  color: #dfeaf2;
                  cursor: default;
                }
              }
            }
            .mail {
              margin-bottom: 12px;
            }
          }
        }
      }
      .mapboxgl-map {
        width: 55%;
        display: block;
        margin-right: 25px;
      }
    }
  }

  .page-template-partner-centers {
    .wrapper-container {
      .map-container {
        .right-column {
          width: 30%;
        }
        .mapboxgl-map {
          width: 60%;
        }
      }
    }
  }
}
